<template>
  <div class="col-lg-6 col-md-12">
    <div>
      <div>
        <b-button v-if="!unredeemedDomainVoucher" :disabled="loading" class="btn-primary" aria-label="Register a New Domain button" @click="registerClick">
          <b-spinner v-if="loading" class="spinner" />
          <div v-else>
            Register a New Domain <b-icon scale="1.5" icon="plus" />
          </div>
        </b-button>
        <b-button v-else :disabled="loading" class="alert" aria-label="Register Your New Domain Now button" @click="redeemVoucher">
          <b-spinner v-if="loading" class="spinner" />
          <div v-else>
            Register Your New Domain Now!
          </div>
        </b-button>
      </div>
      <b-modal v-model="showCompanySelect" title="Select Company" no-close-on-backdrop size="lg" :ok-disabled="!currentCompany" @ok="determineRoute">
        <div>Please select the company you wish to register a new domain under.</div>
        <company-select @input="setSelectedCompany" />
      </b-modal>
    </div>
  </div>
</template>


<script>
import CompanySelect from '@/components/CompanySelect.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddDomains',
  components: {
    CompanySelect,
  },
  props: {
    domains: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showCompanySelect: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('companies', {
      companies: 'companies',
      currentCompany: 'currentCompany',
    }),
    ...mapGetters('jurisdictions', {
      findByName: 'findByName',
    }),
    ...mapGetters('checkout', {
      productIdInCart: 'productIdInCart',
    }),
    ...mapGetters('vouchers', {
      accountDomainVouchers: 'accountDomainVouchers',
    }),
    unredeemedDomainVoucher() {
      return this.accountDomainVouchers.find(voucher => voucher.redeemed === false) || null
    },
  },
  async mounted() {
    await this.reloadVouchers()
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'fetchProduct',
      'setupContext',
    ]),
    ...mapActions('domains', [
      'setDomain',
      'searchDomains',
      'setSelectedDomain',
      'setDomainSearchResults',
    ]),
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('vouchers', [
      'fetchAccountDomainVouchers',
      'fetchCompanyVouchers',
    ]),
    async withLoading(...promises) {
      this.loading = true
      await Promise.all(promises)
      this.loading = false
    },
    async reloadVouchers() {
      await this.withLoading(
        this.fetchAccountDomainVouchers()
      )
    },
    async addBundleToCart() {
      const hireUsProducts = await this.fetchProduct({
        productKind: 'product_bundle',
        productCategory:'business_identity',
        jurisdictionId: this.findByName(this.currentCompany.jurisdictions[0])?.id,
      })
      if (!this.productIdInCart(hireUsProducts[0].id)) await this.addToCart(hireUsProducts[0])
    },
    async registerClick() {
      if (this.companies?.length) {
        await this.withLoading(this.selectCompany())
      } else {
        await this.withLoading(this.routeToStageline())
      }
    },
    async redeemVoucher() {
      await this.withLoading(this.routeToStageline(this.unredeemedDomainVoucher?.company_id, 'configure_domain'))
    },
    async selectCompany() {
      if (this.companies?.length > 1) {
        this.showCompanySelect = true
      } else {
        await this.setSelectedCompany(this.companies[0].id)
        await this.determineRoute()
      }
    },
    async setSelectedCompany(companyId) {
      if (companyId) {
        await Promise.all([
          this.setupContext({ companyId: companyId }),
          this.setCurrentCompany({ id: companyId }),
        ])
      }
    },
    async clearDomain() {
      await Promise.all([
        this.setDomain(null),
        this.setSelectedDomain(null),
        this.searchDomains(null),
        this.setDomainSearchResults(null),
      ])
    },
    async determineRoute() {
      if (this.domains?.length === 0) {
        await this.withLoading(this.routeToStageline(this.currentCompany.id, 'configure_domain'))
      } else {
        await this.withLoading(this.openCheckoutModal())
      }
    },
    async openCheckoutModal() {
      await this.withLoading(
        this.clearDomain(),
        this.addBundleToCart(),
      )
      this.$bvModal.show('checkout-modal')
    },
    async routeToStageline(companyId = null, stageName = 'configure_domain') {
      await this.clearDomain()

      if (companyId) {
        await this.fetchCompanyVouchers(companyId)
        await this.$router.push({ name: 'stageline-v2-stage', params: { companyId: companyId, stageIdOrName: stageName } })
      } else {
        await this.$router.push({ name: 'stageline-v2-landing-page' })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  max-width: 100%;
  padding: 0;

  ::v-deep .btn-primary {
    background-color: #2E3798;
    border-color: #2E3798;

    &:hover, &:active {
      background-color: #2E3798 !important;
      border-color: #2E3798 !important;
    }
  }
}
.spinner {
  width: 1.4rem; height: 1.4rem;
}

// Palette
$alert: #f45151;
$alert2: #f47171;

@mixin btn-hover($hover-color: $alert2, $color: white) {
  border-color: $hover-color;
  background: $hover-color;
  color: $color;
}

@mixin btn-core($padding, $hover-color) {
  width: 100%;
  border: 0.0625em solid $alert;
  font-weight: 800;

  &:hover {
    @include btn-hover();
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.6em 1.875em) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $hover-color);
}

// Buttons: Types
button.alert {
  @include btn-default(
    $primary-color: $alert,
    $hover-color: $alert2,
  );
  margin-bottom: 0 !important;
}
</style>
